import { Link } from "gatsby";
import * as React from "react";
import { Card, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { graphql } from "gatsby";

export default function Terms() {
  return (
    <Layout>
      <main>
        <Container fluid={false}>
          <section>
            <div class="col-xl-12 col-lg-12 col-md-12 pt-3 mx-auto text-center">
              <h1 class="fs-48 fx-blod mb-4">
                Terms and Conditions for Merchants
              </h1>
            </div>
          </section>
          <p>
            These Ts&Cs form a part of the Contract between You (as a Merchant
            who has signed up to promote its brand through the Bogo App) and the
            Bogo App FZ LLC. By signing up as a Merchant, you agree to be bound
            by the terms1 and conditions contained herein.
          </p>
          <p className="primary-color fw-bold mb-1">The Offering</p>
          <p>
            <span className="terms1">(a)</span> Bogo agrees to provide You with
            Access to the Bogo App as a means for You to promote Your product or
            services by offering Voucher Campaigns to consumers who are users of
            the Bogo App. You will access the Bogo App through mobile app or web
            by using Your unique login ID.
            <br />
            <span className="terms1">(b)</span> Bogo agrees to promote Your
            products or services on the Bogo App for as long as You have active
            Voucher Campaigns running. If You have no active Voucher Campaigns,
            Your business may not be listed or viewable to Bogo App users.
            <br />
            <span className="terms1">(c)</span> You may subscribe as a Silver
            Merchant or a Gold Merchant, at Your discretion, and may switch
            between the two tiers of membership at any time within the Account
            Settings section of the Bogo App or website. Any change in Merchant
            tier will take effect immediately, however Fixed Fees that have
            already been paid for the previous Merchant tier are non-refundable.
            If You change tiers from Gold Merchant to Silver Merchant, Your new
            Fixed Fee will be payable on the date Your next Fixed Fee is
            payable. If You change tiers from Silver Merchant to Gold Merchant,
            the difference between the Gold Tier Fee and the Silver Tier Fee
            will be payable immediately for the period from the date of the
            change until the date Your next Fixed Fee is payable. <br />
            <span className="terms1">(d)</span> Bogo agrees to let You set the
            terms of Your Voucher Campaigns provided that the terms fit within
            the terms available to a Silver Tier Merchant or Gold Tier Merchant,
            as set out below:
            <br />
            <span className="terms2">i)</span> Silver Tier Merchants may choose
            Voucher Campaign, the days of the week during which Users can avail
            the Voucher Campaign and must then offer that Voucher Campaign to
            all Users who attempt to avail the Voucher Campaign during opening
            hours of the Merchant’s establishment on those days of the week.
            <br />
            <span className="terms2">ii)</span> Gold Tier Merchants may choose
            the days of the week and the times of the day during which Users can
            avail the Voucher Campaign and must then offer the Voucher Campaign
            to any Users who attempt to avail the Voucher Campaign during the
            relevant operating hours of the Merchant, specified in the Voucher
            Campaign terms, provided that Gold Tier Merchants may also (at their
            discretion) set a maximum number of redemptions of a Voucher
            Campaign, which Voucher Campaign shall become unavailable to any
            other User the moment that maximum number of redemptions has been
            made Voucher Campaign.
            <br />
            <br />
          </p>

          <p className="primary-color fw-bold mb-1">Merchant Obligations</p>
          <p>
            <span className="terms1">(a)</span> You authorize Bogo to advertise
            Your products and/or services on the Bogo App and elsewhere in
            connection with the services provided by Bogo and to use all IP
            Material submitted by You to Bogo through the Bogo App or using any
            media to promote You, Your Voucher Campaigns and Bogo throughout the
            UAE and the Middle East Region. <br />
            <span className="terms1">(b)</span> You represent, undertake and
            acknowledge that You maintain ownership and the right to use all IP
            Material which You submit to Bogo and that You are legally
            authorised to license the use of such IP Material to Bogo in
            accordance with Clause 2(a). You represent, undertake and agree that
            You will maintain all rights of use, permits, consents, licenses and
            approvals to enable the continued compliance with this Clause 2(b)
            at all times. You will not submit any IP Material to Bogo which
            would result in You being in breach of any contract, rights of
            ownership or use of another party and You agree to indemnify Bogo
            for any claims which may be made against it, or for any losses
            suffered whatsoever, as a result of Bogo’s use of Your IP Material.{" "}
            <br />
            <span className="terms1">(c)</span> You agree that You will ensure
            all Users will at all times be able to avail of the Voucher
            Campaigns You have active on the Bogo App at any time in accordance
            with the rules of use applicable to all Users. You agree that Your
            Voucher Campaigns must remain active and redeemable by Users at all
            times during the validity period of such Voucher Campaigns (subject
            to any exclusions specifically mentioned and visible to the User on
            the Bogo App). You agree that You will not make changes to Your
            Voucher Campaigns or add any exclusions not specifically identified
            on the Bogo App, directly with Users when they avail a Voucher
            Campaign and that You will honor the Voucher Campaign strictly
            applying the terms1 set out on the Bogo App. <br />
            <span className="terms1">(d)</span> You understand that should You
            wish to make a change to the terms1 or availability of a Voucher
            Campaign, such changes will only take effect and be available to
            Users after 24 hours has elapsed from the time such changes are
            entered into the Bogo App <br />
            <span className="terms1">(e)</span> You agree that no User will be
            discriminated against or given lesser priority than other customers,
            for bookings and provision of Your products and/or services by
            virtue of the fact that such User will use a Voucher Campaign. No
            User will be offered different or less favourable terms1 of use of
            Your products and/or services than general members of the public.{" "}
            <br />
            <span className="terms1">(f)</span> You agree that under no
            circumstances will You offer a deal directly to any User that is
            identical to a Voucher Campaign they wish to use through the Bogo
            App and that all Voucher Campaigns given to a User must be processed
            through the Bogo App. <br />
            <span className="terms1">(g)</span> You agree that You will not
            inflate Your prices or impose any additional fees or charges for
            Users and that all Voucher Campaigns will be offered applying Your
            usual retail prices and terms1. <br />
            <span className="terms1">(h)</span> You agree that the provisions
            contained in Clauses 2(c) – (g) are fundamental clauses to the
            Contract and that any breach of any of those clauses may be met with
            strict action on the part of Bogo and may lead to immediate
            termination of the Contract and removal of Your Voucher Campaigns
            and IP Material from the Bogo App, together with any other remedies
            that may be available to Bogo. <br />
            <span className="terms1">(i)</span> You agree to notify Bogo in
            writing, promptly and in any event no later than 10 working days
            after any of the following events has occurred: <br />
            <span className="terms2">(i)</span> a change in Your business or
            trading name; <br />
            <span className="terms2">(ii)</span> a change in Your ownership
            structure; <br />
            <span className="terms2">(iii)</span> a material change in the
            services You provide; <br />
            <span className="terms2">(iv)</span> a change in Your address or
            other contact details; or <br />
            <span className="terms2">(v)</span> if You cease to operate for any
            reason, including (but not limited to) by reason of insolvency.{" "}
            <br />
            <span className="terms2">(j)</span> Bogo will ensure You are given
            access to one meeting (in person or via electronic means) to assist
            You to set up Your first Voucher Campaign and show You how to redeem
            vouchers. You agree to attend and engage with this training and You
            undertake to train and keep all of Your relevant staff up to date on
            how to utilize the Bogo App. <br />
            <span className="terms2">(k)</span> Bogo reserves the right at all
            times and for any reason whatsoever, to terminate any Voucher
            Campaign or remove any Voucher Campaign or references to any IP
            Material from the Bogo App at any time. When this occurs, You may
            ask Bogo to provide You with reasons why they have done so to enable
            You to correct any issue and Bogo will respond. However, Bogo has
            sole discretion in respect to this matter and will not enter into
            any protracted discussions with You in these circumstances.
            <br />
          </p>

          <p className="primary-color fw-bold mb-1">Payments</p>
          <p>
            <span className="terms1">(a)</span> You agree that any Fixed Fee and
            all applicable VAT charges will be invoiced at the applicable
            intervals (monthly or annually) from the date of registration and
            will be pre-paid through Bogo’s payment processing system.
            <br />
            <span className="terms1">(b)</span> You agree that no Voucher
            Campaign may be promoted through the Bogo App until the first Fixed
            Fee payment is received by Bogo and that, thereafter, Bogo may
            remove Your Voucher Campaign and IP Material from the Bogo App as
            soon as any Fixed Fee payment is overdue and remains unpaid. In such
            case Bogo may reinstate the Voucher Campaign and IP Material for the
            relevant Merchant as soon as payment is received.
            <br />
            <span className="terms1">(c)</span> You agree that no Brand
            Ambassador Campaign which is not included in the Fixed Fee may
            commence until the Brand Ambassador Fee payment is received by Bogo.
            <br />
            <span className="terms1">(d)</span> The Fixed Fee and the Brand
            Ambassador Fee are non-refundable. Should You decide to cancel any
            Voucher Campaign or Brand Ambassador Campaign, or terminate this
            Contract during a period which has already been paid for, You will
            forfeit the fees already paid and will remain liable to pay any fees
            already invoiced prior to the date You notified Bogo of Your wish to
            discontinue a Voucher Campaign, Brand Ambassador Campaign or the
            Contract.
            <br />
          </p>
          <p className="primary-color fw-bold mb-1">BOGO Obligations</p>
          <p>
            <span className="terms1">(a)</span> In the event of malfunction of
            the Bogo App, Bogo will use all reasonable efforts to get the Bogo
            App operational in the shortest time possible.
            <br />
            (b) Notwithstanding Clause 4(a), Bogo will not be held responsible
            if the Bogo App is not operational for a Merchant or Merchants due
            to a malfunction for any reason (including but not limited to:
            internet service outage; virus; failure of the Bogo App software;
            system overload; or other). You acknowledge that the Bogo App is not
            owned by Bogo and that Bogo has a license to market, sell and use
            the Bogo App only. While Bogo will be responsible for servicing the
            Bogo app, some malfunctions may not be entirely within the control
            of Bogo to fix.
            <br />
            <span className="terms1">(c)</span> In addition to the initial
            training referred to in Clause 2(i), Bogo will offer You regular
            meetings (in any event no less frequently than twice per year) to
            enable You to be updated on new features with the Bogo App and to
            enable You the opportunity to ask any questions you may have in
            relation to the Bogo App.
            <br />
            <span className="terms1">(d)</span> Bogo will provide tech support
            through its online support system, where You may log any issues you
            are facing with the Bogo App. Bogo will use reasonable efforts to
            respond to all non-urgent tech support questions no later than 72
            hours after they have been submitted and to respond to all urgent
            tech support questions no later than 24 hours after they have been
            submitted. <br />
          </p>
          <p className="primary-color fw-bold mb-1" id="privacy">
            Reports and Data Protection
          </p>
          <p>
            <span className="terms1">(a)</span> Bogo collects certain data in
            relation to the number of redemptions of Your Voucher Campaigns made
            through the Bogo App (among other things). You agree to allow Bogo
            to collect, store and process such data, together with other data
            related to You, including (but not limited to) information about
            Your business name, copies of trade licenses and contact details,
            for the purposes of providing the services set out in the Contract.
            While Bogo will use reasonable efforts to safeguard Your
            information, You acknowledge that the security of data maintained or
            transmitted electronically cannot be guaranteed and carries a risk
            that it may be intercepted or accessed by unauthorized third
            parties. Bogo will not take responsibility for the loss or
            unauthorized accessing of Your data in these circumstances. You may
            notify Bogo at any time should You wish Bogo to cease from
            collecting or storing Your data or data about You. However, You
            acknowledge that without this data we may be in a position where we
            are unable to provide You with the services we are agreeing to
            provide to You under this Contract. <br />
            <span className="terms1">(b)</span> From time to time Bogo may ask
            You to provide additional specific data to enable Bogo to provide
            its services more effectively. You may agree to provide such
            additional data at Your sole discretion. Any data that You do
            provide after such a request will be handled in accordance with
            these Tcs&Cs. <br />
            <span className="terms1">(c)</span> Bogo will use the data it
            collects to generate certain reports which may be relevant to Your
            business, including about the redemption patterns of Users who have
            redeemed Your Voucher Campaigns. You may access such reports through
            the Mobile App or Web version of the BOGO App. Bogo may offer
            different or additional reports from time to time for You to access
            and use. <br />
          </p>
          <p className="primary-color fw-bold mb-1">Termination</p>
          <p>
            <span className="terms1">(a)</span> The Contract comes into effect
            on the date You agree to subscribe as a Merchant and shall continue
            indefinitely until such time as the Contract terminated in
            accordance with this Clause 6.
            <br />
            <span className="terms1">(b)</span> Bogo may terminate the Contract
            upon written notice as follows:
            <br />
            <span className="terms2">(i)</span> immediately in the event that
            You become insolvent, are put into liquidation or receivership or
            have an administrator appointed;
            <br />
            <span className="terms2">(ii)</span> upon 5 working days notice in
            the event You commit a material breach of these Ts & Cs, and for
            this purpose a material breach will be deemed to have been committed
            if you have not paid any amount due within 30 days of the date of
            the invoice; or <br />
            <span className="terms2">(iii)</span> upon 30 days notice for any
            reason, and Bogo need not provide a reason in the event of
            termination under this Sub-Clause. <br />
            <span className="terms1">(c)</span> You may terminate these Ts & Cs
            upon giving 30 days written notice by You for any reason. <br />
            <span className="terms1">(d)</span> Upon the termination of the
            Contract for whatever reason, all right and obligations will cease
            immediately other than those which are expressed to survive the
            termination of the Contract. Notwithstanding the foregoing,
            termination is without prejudice to whatever rights and obligations
            arose prior to the date of termination of the Contract. For the
            avoidance of doubt, any amounts which were due and payable before
            and up until the date of termination of the Contract shall remain
            due and payable by the Merchant. <br />
            <span className="terms1">(d)</span> Notwithstanding this Clause 6,
            each of Bogo and You are excused from delays in fulfilling their
            obligations under these Ts & Cs for a period of a maximum of 30 days
            in the event the specific obligation or act is prevented as a result
            of an event of force majeure, and then only strictly to the extent
            such obligation or act is actually so prevented. Where either Bogo
            or You is claiming a force majeure event is preventing performance
            under these Ts & Cs must use all reasonable efforts to find
            alternative means for satisfying the obligation under the Agreement.
            Neither You nor Bogo can terminate the Contract solely by reason of
            a breach which is being caused because of a permitted force majeure
            event, provided that the relevant obligation or act is fulfilled or
            performed immediately performance is possible. For the purposes of
            this Clause, and act of force majeure is strictly limited to an ‘act
            of god”, including but not limited to storm or other severe weather
            incident, flood, fire, tsunami, earthquake, volcanic eruption, war,
            riot, act of terrorism, epidemic or pandemic or any other event
            which is severe, beyond the control of both parties and was not
            reasonably foreseeable.
            <br />
          </p>
          <p className="primary-color fw-bold mb-1">Confidentiality</p>
          <p>
            <span className="terms1">(a)</span> You acknowledge that during the
            course of Your dealings with Bogo under the Contract, You may come
            into contact with Confidential Information about the Bogo App, Bogo
            or the Bogo business model. Under no circumstances will You share
            any such Confidential Information with any third party or use it for
            any purposes other than the purposes of the Contract, without the
            prior consent in writing of Bogo.
            <br />
            <span className="terms1">(b)</span> Notwithstanding Clause 7(a), you
            may share Confidential Information with your advisors and limited
            employees who strictly need to know the information for the purposes
            of fulfillment of Your obligation under these Ts & Cs, and provided
            such parties are also subject to confidentiality obligations. You
            may also share Confidential Information pursuant to a valid and
            enforceable Court order or law or regulation from a government
            Authority You are subject to, but only to the extent strictly
            required to satisfy the Court order or regulation. <br />
            <span className="terms1">(c)</span> You acknowledge that you will
            ensure any person who has access to Confidential Information in
            compliance with Clause 7(b) complies with the obligations of
            Confidentiality and you will bear all responsibility for a breach of
            confidentiality by such persons. <br />
            <span className="terms1">(d)</span>
            Bogo agrees to keep any Confidential Information related to You
            confidential also, on the same terms1 as those identified in Clauses
            7(a), 7(b) and 7(c). <br />
            <span className="terms1">(e)</span> This Clause 7 in it’s entirety,
            shall survive the termination of the Contract.
            <br />
          </p>
          <p className="primary-color fw-bold mb-1">Indemnity</p>
          <p>
            <span className="terms1">(a)</span> To the extent allowed under
            applicable law, You agree to indemnify Bogo against all liabilities,
            costs, expenses, damages and losses (including but not limited to
            any direct, indirect or consequential losses, loss of profit, loss
            of reputation and all interest, penalties and legal costs
            (calculated on a full indemnity basis) and all other professional
            costs and expenses suffered or incurred by the Bogo and its
            Affiliates, and any officers, directors, agents and employees of any
            of them, and hold such parties harmless from and against any claims,
            lawsuits, investigations, penalties, damages, losses or expenses
            (including but not limited to reasonable legal fees and costs)
            arising out of or relating to any of the following: a) any breach or
            alleged breach by You of the Contract; b) any claim by any party for
            unredeemed Voucher Campaigns You have offered or the unredeemed cash
            values of such Voucher Campaigns; c) any claim arising out of a
            violation of any law or regulation governing Your goods and/or
            services; d) any claim arising out of Your violation of any law or
            regulation governing the use, sale, and distribution of alcohol; e)
            any claim by a User or anyone else arising out of or relating to the
            goods and services provided by You, including but not limited to,
            any claims for false advertising, product defects, personal injury,
            death, or property damage; or f) any claim arising out of Your
            misuse of customer data retrieved from Users, or any violation of an
            applicable data privacy or security law by You. Bogo shall in all
            cases control its own defence to any such action or claim and will
            choose and appoint its own legal counsel. <br />
            <span className="terms1">(b)</span> This Clause 8 in it’s entirety,
            shall survive the termination of the Contract.
            <br />
          </p>
          <p className="primary-color fw-bold mb-1">Limitation of Liability</p>
          <p>
            <span className="terms1">(a)</span> Bogo and its Affiliates shall
            not under any circumstance be liable to You in tort (including
            negligence), breach of contract, breach of statutory duty or
            otherwise due to, under and/or arising out of or in connection with
            the Contract if and to the extent that the loss or damage in respect
            of which such liability arises or is claimed to arise within any of
            the following categories (i) loss of profits; (ii) loss of revenue
            or business; (iii) loss of goodwill or reputation; and/or (iv) loss
            of or corruption or damage to data.
            <br />
            <span className="terms1">(b)</span>
            Notwithstanding Clause 9(a), the liability of Bogo and its Affliates
            (in aggregate) shall be capped at AED50,000.
            <br />
            <span className="terms1">(c)</span> This Clause 9 in it’s entirety,
            shall survive the termination of the Contract.
          </p>
          <p className="primary-color fw-bold mb-1">Amendment</p>
          <p>
            <span className="terms1">(a)</span> Bogo may make amendments and
            modifications to these Ts & Cs at any time and all such amendments
            shall be applicable to You after a period of 10 working days has
            elapsed from the date the amendments are notified to You.
            <br />
            <span className="terms1">(b)</span> Notwithstanding Clause 10(a), to
            the extent You do not accept any amendments Bogo has made then You
            may, within the time period specified in Clause 10(a), notify Bogo
            in writing that You are not in agreement with any amendment made,
            such notice to specify specifically the amended Clause You do not
            agree with and stating reasons why. Upon the receipt by Bogo of a
            notice under this Clause, the Agreement in its prior form will
            continue to apply to You and Bogo may sit with You to amicably agree
            in good faith, to modify any amendment made, provided that where You
            ultimately do not agree with any resolution proposed by Bogo, Bogo
            reserves the right to terminate the Contract under Clause 6.
            <br />
            <span className="terms1">(c)</span> For the purposes of this Clause,
            You agree that any amendments will be deemed notified to you on the
            later of the date when they are made available on the Bogo website
            and the Bogo App and You will be deemed to have read and accepted
            any such amendments so long as you continue to do business with Bogo
            and have not served Bogo with notice under Clause 10(b) or a
            termination notice, after the time period set out in Clause 10(a)
            has elapsed.
            <br />
          </p>
          <p className="primary-color fw-bold mb-1">Miscellaneous Provisions</p>
          <p>
            <span className="terms1">(a)</span> The Contract constitutes the
            entire agreement between You and Bogo in respect of the subject
            matter of the Contract and supersedes any terms1 previously agreed
            between You and Bogo.
            <br />
            <span className="terms1">(b)</span> You may not transfer or assign
            your rights under the Contract to any third party without the prior
            written consent of Bogo, which consent will not be unreasonably
            withheld in the event of a sale of Your business.
            <br />
            <span className="terms1">(c)</span> Bogo may transfer or assign the
            Contract to any Affiliate or to any legitimate third party purchaser
            of its business or part of its business without your consent,
            provided that the terms1 of the Contract immediately following such
            transfer or assignment are no less favourable to You and provided
            that You are notified of the transfer or assignment in writing where
            such transfer or assignment is to a third party purchaser.
            <br />
            <span className="terms1">(d)</span> The Contract shall be binding
            on, and ensure to the benefit of You and Bogo and their respective
            personal representatives, successors and permitted assigns, and
            references to either You or Bogo shall include their personal
            representatives, successors and permitted assigns. In the case of a
            change in Your management/ownership including but not limited to the
            case where Your business name changes during the term of the
            Contract, You agree to do all acts necessary to
            assign/transfer/novate the Contract (subject to Clause 10(b)) onto
            such new third party entity so that the Contract remains valid and
            in force until it termination.
            <br />
            <span className="terms1">(e)</span> No relaxation, extension or
            delay by You or Bogo in enforcing any provision of the Contract or
            in exercising any right or remedy to which either You or Bogo may be
            entitled under the Contract or the granting of time by either of You
            or Bogo to the other party shall constitute a waiver or prejudice or
            restrict the rights and powers of that party. No waiver of any
            provision of these Ts & Cs shall be effective unless notified in
            writing by the party granting the waiver and the waiver of any
            breach of the Contract shall not constitute a waiver of any
            subsequent or other breach.
            <br />
            <span className="terms1">(e)</span> If any provision of the Contract
            should be held to be invalid or unenforceable, the validity and
            enforceability of the remaining provisions of the Contract are not
            affected.
            <br />
            <span className="terms1">(f)</span> Except as expressly stated in
            these Ts & Cs, Bogo does not make any representations or warranties,
            express or implied, including but not limited to any implied
            warranty of merchantability or fitness for a particular purpose of
            the Bogo App, or non-infringement. Bogo does not warrant or
            guarantee that the services offered on or through the Bogo App will
            be uninterrupted or error-free, that the Voucher Campaigns are
            error-free or that any Voucher Campaign will result in any revenue
            or profit for You.
            <br />
            <span className="terms1">(g)</span> Nothing in the Contract shall be
            construed as or have the effect of constituting any relationship of
            employee and employer, partnership, joint venture, agency or other
            such relationship between Bogo and You.
            <br />
            <span className="terms1">(h)</span> Any notice given pursuant to the
            Contract shall be in writing and in the English language and sent by
            means of courier, registered post or email, to such address, or
            email address as may be notified in writing by You and/or Bogo from
            time to time. A notice shall be deemed (in the absence of evidence
            of earlier receipt) to have been delivered in the case of courier,
            twenty-four (24) hours after dispatch to the relevant address and in
            the case of email, at the time the email reaches such email address.{" "}
            <br />
            The Parties acknowledge and agree that an invoice issued pursuant to
            these Ts & Cs may be sent to a Party electronically to the email
            address on record with Bogo and is deemed received when it has
            reached such email address. Any notice given under or in connection
            with the Contract shall be in the English language.
            <br />
            <span className="terms1">(i)</span> The Contract is drafted in the
            English language. If the Contract is translated into any other
            language from English, the English language version shall prevail to
            the extent of any inconsistency.
            <br />
            <span className="terms1">(j)</span> The Contract shall be governed
            by and construed in accordance with the laws of the United Arab
            Emirates, as applied in Dubai International Financial Centre (DIFC).
            Any dispute or claim arising out of or in connection with the
            Contract or its subject matter or formation (including
            non-contractual disputes or claims), including any question
            regarding its existence, validity or termination, shall be subject
            to the non-exclusive jurisdiction of the courts of the Dubai
            International Financial Centre.
            <br />
            <span className="terms1">(k)</span> The currently applicable
            Merchant Ts&Cs will be available and accessible to You on the Bogo
            App and You agree that they will be applicable to You and valid and
            binding as between You and Bogo from the date you subscribe to be a
            Merchant on the Bogo App. You will be deemed to have read and
            accepted the Ts&Cs so long as You continue to do business with Bogo
            or use the Bogo App and no physical signature of the Ts&Cs is
            required by either You or Bogo.
            <br />
          </p>
          <p className="primary-color fw-bold mb-1">DEFINITIONS</p>

          <p>
            <b>Affiliates</b> means Bogo’s parent company or any subsidiary of
            Bogo or any other subsidiary of Bogo’s parent company. <br />
            <b>Bogo</b> - means the Bogo App FZ LLC, a company incorporated and
            registered with the Dubai Development Authority in the TECOM
            freezone in Dubai, under registration number 99245. <br />
            <b>Bogo App</b> – means the mobile application offered to Merchants
            by Bogo, through which Merchants can promote offerings in relation
            to their products and services and all software related to that
            application.
            <br />
            <b>Brand Ambassador Campaign</b> – means a promotional campaign
            offered by a brand ambassador signed up by Bogo for a particular
            Merchant brand.
          </p>
          <p>
            <b>Brand Ambassador Fee</b> – means a fee payable to Bogo where a
            Merchant contracts to proceed with a Brand Ambassador Campaign which
            is not already covered under the Fixed Fee arrangement, such fee to
            be exclusive of VAT. <br />
            <b>Confidential Information </b>– means, in relation to either
            party, any information about that party and its business, including
            know how and information related to employees, officers,
            contractors, agents, customers and/or contacts, financial
            information, products, services, the terms1 of the Contract,
            intellectual property and any other information that is marked
            confidential, in all cases that the recipient ought reasonably to
            have known was confidential, and is provided to the other party or
            any of its employees, contractors or advisors pursuant to these Ts &
            Cs.Confidential Information excludes information that: (i) is or
            comes into the public domain other than by way of a breach of any
            confidentiality obligation under these Ts & Cs; (ii) is disclosed by
            a third party (except where such third party discloses such
            information in breach of obligations of confidence); or (iii) is
            independently developed by a party without recourse to the
            Confidential Information.
            <br />
            <b>Contract </b>– means the legal agreement between You and Bogo for
            the provision of products and services by Bogo to You, including the
            Ts&Cs. <br />
            <b>Fixed Fee </b>– means a Gold Tier Fee or a Silver Tier Fee, as
            the case may be.
          </p>
          <p>
            <b>Gold Tier Fee </b>– means a fixed monthly fee payable by each
            gold tier Merchant of 150 AED or annual fee of 1,500 AED (at the
            option of the Merchant), in each case exclusive of VAT, which fee
            shall include full access to the Bogo App as a Gold Tier Merchant.
            <br />
            <b>Gold Tier Merchant</b> – means a Merchant who has paid the Fixed
            Fee up to date and is authorized to offer Voucher Campaigns applying
            the terms1 set out in Clause 1(d)(ii).
            <br />
            <b>IP Material</b> – means all information, branding, images, logos,
            trade names and other intellectual property which Bogo is given
            access to during the term of the Contract.
            <br />
            <b>Merchant</b> – means a company which is a goods and/or services
            provider and has agreed to sign up for the service offering provided
            by Bogo and promote its goods and/or services through The Bogo App.{" "}
            <br />
            <b>Silver Tier Fee</b> – means a fixed monthly fee payable by each
            silver tier Merchant of 100 AED or annual fee of 1,000 AED (at the
            option of the Merchant), in each case exclusive of VAT, which fee
            shall include full access to the Bogo App as a Silver Tier Merchant.
            Silver Tier Merchant – means a Merchant who has paid the Fixed Fee
            up to date and is authorized to offer Voucher Campaigns applying the
            terms1 set out in Clause 1(d)(i).
            <b>Ts&Cs </b>– means these Terms and Conditions of contract.
            <br />
          </p>
          <p>
            <b>User</b> – means a consumer who has downloaded, uses or
            subscribed for the Bogo App to avail Voucher Campaigns. <br />
            <b>Voucher Campaign </b>– means the promotional campaigns which the
            Merchant will offer from time to time through the Bogo App. <br />
            <b>You</b> – means the specific Merchant to whom these Ts&Cs apply.
          </p>
        </Container>
      </main>
    </Layout>
  );
}
